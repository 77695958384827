// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-hinterlass-eine-nachricht-js": () => import("./../../../src/pages/de/hinterlass-eine-nachricht.js" /* webpackChunkName: "component---src-pages-de-hinterlass-eine-nachricht-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-leave-a-message-js": () => import("./../../../src/pages/en/leave-a-message.js" /* webpackChunkName: "component---src-pages-en-leave-a-message-js" */),
  "component---src-pages-hr-index-js": () => import("./../../../src/pages/hr/index.js" /* webpackChunkName: "component---src-pages-hr-index-js" */),
  "component---src-pages-hr-leave-a-message-js": () => import("./../../../src/pages/hrLeave a message.js" /* webpackChunkName: "component---src-pages-hr-leave-a-message-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-leave-a-message-js": () => import("./../../../src/pages/pl/leave-a-message.js" /* webpackChunkName: "component---src-pages-pl-leave-a-message-js" */),
  "component---src-pages-zanechat-vzkaz-js": () => import("./../../../src/pages/zanechat-vzkaz.js" /* webpackChunkName: "component---src-pages-zanechat-vzkaz-js" */)
}

